import {
  Divider,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DownArrow from "../../assets/icons/down-arrow.svg";
import EyeIcon from "../../assets/icons/eye.svg";
import NoDataIcon from "../../assets/icons/no-data.svg";
import {
  getJobList,
  updateJobStatus,
} from "../../controller/services/manage-jobs";
import { setBreadCrumb } from "../../redux/slices/common_slice";
import { setLoading } from "../../redux/slices/flags_slice";
import NoDataFound from "../common/NoDataFound";
const columns = [
  "S. no",
  "Job Title",
  "Total Pay",
  "Hospital Name",
  "Start Date",
  "Duration",
  "Location",
  "Open Positions",
  "Actions",
  "View",
];
export default function ManageJobs() {
  const [page, setPage] = React.useState(0);
  const [usersList, setUsersList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const rowsPerPage = 10;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchJobList();
    const breadCrumbArr = [{ name: "Manage Jobs", to: "" }];
    dispatch(setBreadCrumb(breadCrumbArr));
  }, [page]);
  const fetchJobList = () => {
    dispatch(setLoading(true));
    const payload = {
      page: page + 1,
      limit: 10,
    };
    getJobList(payload)
      .then((resp) => {
        setUsersList(resp.data.list);
        setTotalCount(resp.data.resultCount);
        console.log(resp, "fdsfsf");
      })
      .catch((Err) => {
        console.log(Err, "fsdf");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const handleStatusChange = (ev, id) => {
    dispatch(setLoading(true));
    const payload = {
      id,
      status: ev.target.value,
    };
    updateJobStatus(payload)
      .then((resp) => {
        toast.success(resp.message);
        if (page != 0) {
          setPage(0);
        } else {
          fetchJobList();
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
  return usersList?.length > 0 ? (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
      }}
    >
      <>
        <TableContainer sx={{ maxHeight: 600, height: 600 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column}
                    align={"center"}
                    style={{ minWidth: "50px" }}
                    sx={{ fontWeight: 600, minWidth: 150 }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {usersList.map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell style={{ minWidth: "50px" }} align="center">
                      {(index == 9 ? page + 1 : page) +
                        `${index == 9 ? "0" : index + 1}`}
                    </TableCell>
                    <TableCell
                      style={{
                        minWidth: "50px",
                        maxWidth: "80px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      align={"center"}
                    >
                      {row?.title}
                    </TableCell>
                    {/* <TableCell>{row?.lastName}</TableCell> */}
                    <TableCell style={{ minWidth: "50px" }} align={"center"}>
                      $
                      {row?.weeklyPay +
                        row?.houseAllowance +
                        row?.mealAllowance}
                    </TableCell>
                    <TableCell style={{ minWidth: "50px" }} align={"center"}>
                      {row?.hospitalDetails?.hospitalName}
                    </TableCell>
                    <TableCell style={{ minWidth: "50px" }} align={"center"}>
                      <Typography
                        marginLeft={"10px"}
                        fontSize={13}
                        sx={{
                          minWidth: "50px",
                          maxWidth: "80px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row?.startDate
                          ? format(new Date(row?.startDate), "dd MMM, yyyy")
                          : ""}
                      </Typography>
                    </TableCell>
                    {/* <TableCell align={"center"}>{row?.description}</TableCell> */}
                    <TableCell style={{ minWidth: "50px" }} align={"center"}>
                      {row?.duration} Weeks
                    </TableCell>
                    {/* <TableCell>{row?.state}</TableCell> */}
                    {/* <TableCell>{row?.zip}</TableCell> */}
                    {/* <TableCell>{row?.description}</TableCell> */}
                    {/* <TableCell>{row?.description}</TableCell> */}
                    <TableCell style={{ minWidth: "50px" }} align={"center"}>
                      {row?.hospitalDetails?.city}
                    </TableCell>
                    <TableCell align={"center"} style={{ minWidth: "50px" }}>
                      <Typography
                        marginLeft={"10px"}
                        fontSize={13}
                        sx={{
                          minWidth: "50px",
                          maxWidth: "80px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {row?.numberOfOpenings}
                      </Typography>{" "}
                      {/* <img
                        src={EyeIcon}
                        onClick={() =>
                          navigate(`/manage-hospitals/users/${row?._id}`)
                        }
                        style={{ cursor: "pointer" }}
                      /> */}
                    </TableCell>
                    <TableCell style={{ minWidth: "50px" }} align={"center"}>
                      <Select
                        name="experienceLevel"
                        placeholder="Select your level of care experience"
                        renderValue={(value) => (
                          <Typography
                            fontSize="13px"
                            fontWeight={600}
                            lineHeight="29px"
                            color="#0d2645"
                          >
                            {value === "pending"
                              ? "Pending"
                              : value === "declined"
                              ? "Declined"
                              : "Approved"}
                          </Typography>
                        )}
                        inputProps={{
                          style: {
                            padding: "10px",
                          },
                        }}
                        MenuProps={{
                          style: {
                            padding: "0 !important",
                          },
                          sx: {
                            "& .Mui-focusVisible": {
                              bgcolor: "transparent",
                            },
                          },
                        }}
                        value={row?.status}
                        IconComponent={(props) => {
                          return (
                            <img
                              src={DownArrow}
                              alt="arrow"
                              style={{ marginRight: "5px", top: "3px" }}
                              {...props}
                            />
                          );
                        }}
                        sx={{
                          padding: "10px 5px 10px 0",
                          background: "#eff6f8",
                          color: "#0d2645",
                          width: "100%",
                          height: "30px",
                          textAlign: "left",
                          borderRadius: "10px",
                          "&:after": {
                            borderBottom: "none",
                          },
                          "&:before": {
                            borderBottom: "none",
                          },
                          "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "none",
                          },
                          "&.Mui-focused": {
                            border: "none",
                            outline: "none",
                          },
                        }}
                        onChange={(e) => handleStatusChange(e, row?._id)}
                      >
                        <MenuItem
                          value="approve"
                          sx={{
                            height: "30px",
                            width: "100%",
                            fontSize: "13px",
                            fontWeight: 600,
                            lineHeight: "29px",
                            display: "flex",
                            alignItems: "center",
                            color: "#0d2645",
                            padding: "20px 8px",
                            "&.Mui-selected": {
                              background: "none",
                            },
                          }}
                        >
                          Approve
                        </MenuItem>
                        <Divider
                          sx={{
                            marginTop: "0 !important",
                            marginBottom: "0 !important",
                          }}
                        />
                        <MenuItem
                          value="decline"
                          sx={{
                            height: "30px",
                            width: "100%",
                            fontSize: "13px",
                            fontWeight: 600,
                            lineHeight: "29px",
                            display: "flex",
                            alignItems: "center",
                            color: "#0d2645",
                            padding: "20px 8px",
                            "&.Mui-selected": {
                              background: "none",
                            },
                          }}
                        >
                          Decline
                        </MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell align={"center"} style={{ minWidth: "50px" }}>
                      <img
                        src={EyeIcon}
                        onClick={() => navigate(`/manage-jobs/${row?._id}`)}
                        style={{ cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </>
    </Paper>
  ) : (
    <NoDataFound title={"No Jobs Found"} icon={NoDataIcon} />
  );
}
