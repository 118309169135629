import {
  Box,
  Button,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "../../../assets/icons/edit-profile.svg";
import JobIcon from "../../../assets/icons/jobs.svg";
import LogoutIcon from "../../../assets/icons/logout-modal-icon.svg";
import LogoutBtnIcon from "../../../assets/icons/logout.svg";
import JobIcon2 from "../../../assets/icons/manage-jobs.svg";
import MenuLogo from "../../../assets/icons/menu-logo.svg";
import NurseIcon from "../../../assets/icons/nurse.svg";
import PasswordIcon from "../../../assets/icons/password.svg";
import CommonModal from "../modals/commonModal";

const menuList = [
  // {
  //   index: 0,
  //   title: "Dashboard",
  //   Icon: DashboardIcon,
  //   to: "/admin/dashboard",
  // },
  {
    index: 0,
    title: "Manage hospitals",
    Icon: JobIcon,
    to: "/manage-hospitals",
  },
  {
    index: 1,
    title: "Manage staff",
    Icon: NurseIcon,
    to: "/manage-nurses",
  },
  {
    index: 2,
    title: "Manage Jobs",
    Icon: JobIcon2,
    to: "/manage-jobs",
  },
  {
    index: 3,
    title: "Profile",
    Icon: EditIcon,
    to: "/profile",
  },
  {
    index: 4,
    title: "Change password",
    Icon: PasswordIcon,
    to: "/change-password",
  },
  {
    index: 5,
    title: "Reporting",
    Icon: JobIcon2,
    to: "/reporting",
  },
];

export default function Sidebar() {
  const [activeMenu, setActiveMenu] = useState(0);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const navigate = useNavigate();
  const styles = {
    color: "#0d2645",
    fontSize: "16px",
    lineHeight: "18.5px",
    fontWeight: 500,
    margin: {
      xs: "15px 0",
      sm: "15px 0",
      md: "15px 0",
      lg: "15px",
      xl: "15px",
    },
    padding: "10px 20px",
    borderRadius: "10px",
    // marginLeft: "10px",
  };

  const handleClickMenu = (item) => {
    setActiveMenu(item.index);
    navigate(item.to);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.reload();
  };
  return (
    <Box
      display={"flex"}
      sx={{
        alignItems: {
          xs: "flex-start",
          sm: "flex-start",
          md: "flex-start",
          lg: "flex-start",
          xl: "flex-start",
        },
        justifyContent: {
          xs: "center",
          sm: "center",
          md: "flex-start",
          lg: "flex-start",
          xl: "flex-start",
        },
      }}
      flexDirection={"column"}
    >
      <img
        className="mobile-iPad-d-none"
        src={MenuLogo}
        alt="app-logo"
        style={{ margin: "0 auto 30px" }}
      />
      <Box
        xs={{
          padding: {
            xs: 0,
            xm: 0,
            md: "40px 0",
            lg: "40px 0",
            xl: "40px 0",
          },
        }}
      >
        <MenuList variant="selectedMenu">
          {menuList.map((item) => {
            const isActive = item.index === activeMenu;
            return (
              <MenuItem
                key={item.index}
                onClick={() => handleClickMenu(item)}
                sx={{
                  ...styles,
                  ...(isActive && {
                    color: "#fff",
                    background: "#38879f",
                    padding: "10px 20px",
                    borderRadius: "10px",
                  }),
                  "&:hover": {
                    color: "#0d2645",
                    padding: "10px 20px",
                    borderRadius: "10px",
                  },
                }}
              >
                <ListItemIcon>
                  <img src={item.Icon} alt="app-icon" />
                </ListItemIcon>
                <Typography variant="inherit">{item.title}</Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "60px",
          left: "25px",
        }}
      >
        <Button
          onClick={() => setOpenLogoutModal(true)}
          sx={{
            color: "#0d2645",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "18.53px",
            textAlign: "left",
            display: "flex",
            justifyContent: "left",
            width: "194px",
            border: "2px solid #eff6f8",
            padding: "10px",
            borderRadius: "15px",
          }}
        >
          <img src={LogoutBtnIcon} alt="logout" />
          <Typography marginLeft={2}>Logout</Typography>
        </Button>
      </Box>
      <CommonModal
        title="Are you sure you want to logout from this account?"
        open={openLogoutModal}
        icon={LogoutIcon}
        onSubmit={handleLogout}
        onClose={() => setOpenLogoutModal(false)}
        button1Text={"Yes"}
        button2Text={"No"}
      />
    </Box>
  );
}
